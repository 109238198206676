














import { computed, defineComponent, watch } from '@vue/composition-api';
import FcSideBar from '@/components/SideBar.vue';
import FcNotification from '@/components/Notification.vue';
import consoleMenu from '@/composition/consoleMenu';
import core from '@/admin/core';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcConfirm from '@/components/FcConfirm.vue';

export default defineComponent({
  name: 'App',
  components: {
    FcSideBar,
    FcNotification,
    FcRoleLoading,
    FcConfirm,
  },
  setup(_, context) {
    // ロールとコンソールメニューの取得
    const isLoadingMyRole = computed(() => myAttributes.isLoading);
    const myRoleSettings = computed(() => myAttributes.myRoleSettings);
    const path = computed(() => context.root.$route.path);
    const isPreview = computed(() => path.value.includes('/preview'));

    const init = () => {
      myAttributes.getMyRole();
      consoleMenu.fetchConsoleMenu();
    };

    if (core.isAuthenticated) {
      init();
    }

    watch(
      () => core.isAuthenticated,
      () => {
        if (core.isAuthenticated) {
          init();
        }
      }
    );

    return {
      isAuthenticated: core.isAuthenticated,
      isLoadingMyRole,
      myRoleSettings,
      isPreview,
    };
  },
});
