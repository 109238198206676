













import { defineComponent } from '@vue/composition-api';
import { active, message, cancel, agree } from '@/composition/confirm';

export default defineComponent({
  name: 'Confirm',
  setup() {
    return { active, message, cancel, agree };
  },
});
