import { ref } from '@vue/composition-api';

const active = ref(false);
const message = ref('');
const color = ref('');
const icon = ref('');
const timeout = ref(5000);

interface Option {
  timeout?: number;
  icon?: string;
}

const notify = (msg: string, option?: Option) => {
  active.value = true;
  message.value = msg;
  if (option) {
    icon.value = option.icon || '';
    timeout.value = option.timeout || 5000;
  }
  color.value = 'success';
};

const warning = (msg: string, option?: Option) => {
  active.value = true;
  message.value = msg;
  if (option) {
    icon.value = option.icon || '';
    timeout.value = option.timeout || 5000;
  }
  color.value = 'warning';
};

const error = (error: unknown, option?: Option) => {
  active.value = true;
  message.value = error instanceof Error ? error.message : (error as string) || '不明なエラー';
  if (option) {
    icon.value = option.icon || '';
    timeout.value = option.timeout || 5000;
  }
  color.value = 'error';
};

const useNotification = () => {
  return {
    notify,
    error,
    warning,
  };
};

export { active, message, color, timeout, icon, useNotification };
