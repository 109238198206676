import core from '@/admin/core';

export interface Season {
  expirationDate: number;
  isActive: boolean;
  seasonId: number;
  seasonName: string;
}

export interface Attributes {
  username: string;
  roleId: string;
  linkedOfficialUserIds: string[];
  linkedSubscriptionPlanIds: string[];
}

const verifyEmailCode = async (code: string) => {
  return core.httpClient.post('/admin/public/invite/verify', { code });
};

const savePhonenumber = (phoneNumber: string) => {
  return core.httpClient.post('/admin/public/phone', { phoneNumber });
};

const signup = async (props: {
  email: string;
  phoneNumber: string;
  password: string;
  emailAuthCode: string;
  phoneAuthCode: string;
}) => {
  const result = await core.httpClient.post('/admin/public/signup', props);
  core.setToken(result.data);
};

const signin = async (email: string, password: string) => {
  await core.httpClient.post('/admin/public/signin', { email, password });
};

const verify = async (email: string, password: string, code: string, isKeepSession: boolean) => {
  const result = await core.httpClient.post('/admin/public/signin/verify', { email, password, code });
  core.setToken(result.data, isKeepSession);
};

const signout = async () => {
  await core.httpClient.post('/admin/public/signout');
  core.clearToken();
};

const updateEmail = async (updateEmail: string) => {
  return core.httpClient.post('/admin/public/email', { updateEmail });
};

const updateEmailVerify = async (code: string) => {
  return core.httpClient.post('/admin/public/email/verify', { code });
};

const updatePassword = async (nowPassword: string, newPassword: string) => {
  return core.httpClient.post('/admin/public/password', { nowPassword, newPassword });
};

const updatePhoneNumber = async (updatePhoneNumber: string) => {
  return core.httpClient.post('/admin/public/phoneNumber', { updatePhoneNumber });
};

const updatePhoneNumberVerify = async (code: string) => {
  return core.httpClient.post('/admin/public/phoneNumber/verify', { code });
};

const remindPassword = async (email: string) => {
  return core.httpClient.post('/admin/public/remind', { email });
};

const remindPasswordVerify = async (code: string, newPassword: string) => {
  return core.httpClient.post('/admin/public/remind/verify', { code, newPassword });
};

export const useAuth = () => {
  return {
    verifyEmailCode,
    savePhonenumber,
    signup,
    signin,
    verify,
    signout,
    updateEmail,
    updateEmailVerify,
    updatePassword,
    updatePhoneNumber,
    updatePhoneNumberVerify,
    remindPassword,
    remindPasswordVerify,
  };
};

export const getSeasons = async () => {
  if (process.env.VUE_APP_GROUP_TYPE === 'season') {
    const result = await core.httpClient.get('/admin/public/seasons');
    return result.data as Season[];
  } else return [] as Season[];
};

/**
 * 管理ユーザー情報取得API
 *
 * @returns 管理ユーザー情報
 */
export const getAttributes = async () => {
  const result = await core.httpClient.get('/admin/public/attributes');
  return result.data as Attributes;
};
