import { ref, Ref } from '@vue/composition-api';

const active = ref(false);
const message = ref('');
const resolver: Ref<Function | undefined> = ref();

const confirmDialog = (msg: string) => {
  return new Promise((resolve) => {
    active.value = true;
    message.value = msg;
    resolver.value = resolve;
  });
};

const closeConfirmDialog = () => {
  active.value = false;
  message.value = '';
  resolver.value = undefined;
};

const agree = () => {
  if (resolver.value) resolver.value(true);
  closeConfirmDialog();
};

const cancel = () => {
  if (resolver.value) resolver.value(false);
  closeConfirmDialog();
};

const useConfirm = () => {
  return {
    confirmDialog,
  };
};

export { active, message, useConfirm, cancel, agree, confirmDialog };
