import Axios, { AxiosResponse, Method } from 'axios';
import { ref } from '@vue/composition-api';

export interface InitProps {
  apiEndpoint?: string;
  hostingUrl?: string;
}

class Core {
  private _hostingUrl = '';
  private _isAuthenticated = ref(false);
  private _isPermitted = true;
  private refreshRequest: Promise<AxiosResponse> | null = null;
  readonly _httpClient = Axios.create();

  readonly httpClient = {
    request: async (props: { method: Method; url: string; data?: object }) => {
      try {
        return await this._httpClient(props);
      } catch (error) {
        if (Axios.isAxiosError(error)) {
          if (error.response?.status === 401) {
            await this.refreshAccessToken();
            if (this.isAuthenticated) {
              return await this._httpClient(props);
            } else {
              throw error.response?.data?.message;
            }
          } else {
            throw error.response?.data?.message;
          }
        } else {
          throw error;
        }
      }
    },
    get: (url: string) => {
      return this.httpClient.request({ method: 'get', url });
    },
    post: (url: string, data?: object) => {
      return this.httpClient.request({ method: 'post', url, data });
    },
    put: (url: string, data?: object) => {
      return this.httpClient.request({ method: 'put', url, data });
    },
    delete: (url: string) => {
      return this.httpClient.request({ method: 'delete', url });
    },
  };

  get isAuthenticated() {
    return this._isAuthenticated.value;
  }
  get isPermitted() {
    return this._isPermitted;
  }
  get hostingUrl() {
    return this._hostingUrl;
  }
  get isKeepSession() {
    return window.localStorage.getItem('isKeepSession') === 'true';
  }

  async init(props: InitProps) {
    this._httpClient.defaults.baseURL = props.apiEndpoint;
    this._httpClient.defaults.withCredentials = true;
    this._hostingUrl = props.hostingUrl || '';

    // ページ離脱時の挙動
    window.onbeforeunload = () => {
      if (this.isKeepSession) return;
      window.localStorage.removeItem('refreshToken');
      window.localStorage.setItem('_beforeunload', Date.now().toString()); // 別タブでログイン状態を維持するためにイベントを発火
    };

    // セッション管理用のイベントハンドラ
    window.addEventListener('storage', (event) => {
      // 別タブをクローズ時に、このタブがログイン状態を保持していれば、ログイン状態を維持する
      if (event.key === '_beforeunload') {
        const refreshToken = this._getRefreshToken();
        if (refreshToken) this._setRefreshToken(refreshToken);
      }
      // 別タブでサインアウト時に、このタブもサインアウトする
      if (event.key === '_signout') {
        this._clearAccessToken();
        this._clearRefreshToken();
      }
    });

    // アクセストークンのリフレッシュ
    await Promise.all([this.getIpRestrictions(), this.refreshAccessToken()]);
  }

  // IP制限の有無確認
  private async getIpRestrictions() {
    const result = await this.httpClient.get('/admin/public/iprestrictions');
    this._isPermitted = result.data.isPermitted;
  }

  private async refreshAccessToken() {
    if (this.refreshRequest) return this.refreshRequest; // リクエストスロットリング
    try {
      const refreshToken = this._getRefreshToken();
      const refreshTokenV2 = this._getRefreshTokenV2();
      if (!refreshToken) return;
      this.refreshRequest = this.httpClient.post('/admin/public/refresh', { refreshToken, refreshTokenV2 });
      const result = await this.refreshRequest;
      const accessToken = result.data.accessToken;
      this._setAccessToken(accessToken);
      this._setRefreshToken(refreshToken);
    } catch (error) {
      this._clearAccessToken();
      this._clearRefreshToken();
    }
    this.refreshRequest = null;
  }

  private _setAccessToken(accessToken: string) {
    this._httpClient.defaults.headers = {
      authorization: `Bearer ${accessToken}`,
    };
    window.dispatchEvent(new Event('signin'));
    this._isAuthenticated.value = true;
  }

  private _clearAccessToken() {
    this._httpClient.defaults.headers = {
      authorization: null,
    };
    this._isAuthenticated.value = false;
    window.dispatchEvent(new Event('signout'));
  }

  private _getRefreshToken() {
    return window.localStorage.getItem('refreshToken') || window.sessionStorage.getItem('refreshToken');
  }

  private _setIsKeepSession(isKeepSession: boolean) {
    window.localStorage.setItem('isKeepSession', String(isKeepSession));
  }

  private _setRefreshToken(refreshToken: string) {
    window.localStorage.setItem('refreshToken', refreshToken);
    window.sessionStorage.setItem('refreshToken', refreshToken);
  }

  private _clearRefreshToken() {
    window.localStorage.removeItem('refreshToken');
    window.sessionStorage.removeItem('refreshToken');
  }

  private _getRefreshTokenV2() {
    return window.localStorage.getItem('refreshTokenV2');
  }

  private _setRefreshTokenV2(refreshTokenV2: string) {
    window.localStorage.setItem('refreshTokenV2', refreshTokenV2);
  }

  private _clearRefreshTokenV2() {
    window.localStorage.removeItem('refreshTokenV2');
  }

  setToken(props: { accessToken: string; refreshToken: string; refreshTokenV2: string }, isKeepSession?: boolean) {
    this._setAccessToken(props.accessToken);
    this._setRefreshToken(props.refreshToken);
    this._setRefreshTokenV2(props.refreshTokenV2);
    this._setIsKeepSession(!!isKeepSession);
  }

  clearToken() {
    this._clearAccessToken();
    this._clearRefreshToken();
    this._clearRefreshTokenV2();
    window.localStorage.setItem('_signout', Date.now().toString()); // 別タブもサインアウトさせるためにイベントを発火
  }
}

export default new Core();
