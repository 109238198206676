import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import core from '@/admin/core';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () => import('@/views/Signin.vue'),
    meta: { public: true },
  },
  {
    path: '/passwordreminder',
    name: 'PasswordReminder',
    component: () => import('@/views/PasswordReminder.vue'),
    meta: { public: true },
  },
  {
    path: '/passwordreminder/verify',
    name: 'PasswordReminderVerify',
    component: () => import('@/views/PasswordReminderVerify.vue'),
    meta: { public: true },
  },
  {
    path: '/signup/:code',
    name: 'Signup',
    component: () => import('@/views/Signup.vue'),
    props: true,
    meta: { public: true },
  },
  {
    path: '/topics',
    name: 'Topics',
    component: () => import('@/views/Topics.vue'),
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('@/views/Users.vue'),
  },
  {
    path: '/user/:userId',
    name: 'User',
    component: () => import('@/views/User.vue'),
    props: true,
  },
  {
    path: '/leave',
    name: 'Leave',
    component: () => import('@/views/Leave.vue'),
  },
  {
    path: '/testusers',
    name: 'TestUsers',
    component: () => import('@/views/TestUsers.vue'),
  },
  {
    path: '/administrators',
    name: 'Administrators',
    component: () => import('@/views/Administrators.vue'),
    props: true,
  },
  {
    path: '/forms',
    name: 'Forms',
    component: () => import('@/views/Forms.vue'),
  },
  {
    path: '/forms/create',
    name: 'CreateForm',
    component: () => import('@/views/Form.vue'),
    props: true,
  },
  {
    path: '/forms/:formId',
    name: 'EditForm',
    component: () => import('@/views/Form.vue'),
    props: true,
  },
  {
    path: '/forms/:formId/entries',
    name: 'Entries',
    component: () => import('@/views/Entries.vue'),
    props: true,
  },
  {
    path: '/favorites',
    name: 'Favorites',
    component: () => import('@/views/Favorites.vue'),
  },
  {
    path: '/favorite/create',
    name: 'Favorite',
    component: () => import('@/views/Favorite.vue'),
  },
  {
    path: '/favorite/:favoriteItemId',
    name: 'FavoriteEdit',
    component: () => import('@/views/Favorite.vue'),
    props: true,
  },
  {
    path: '/favorites/settings',
    name: 'FavoriteSettings',
    component: () => import('@/views/FavoritesSettings.vue'),
  },
  {
    path: '/favorites/setting/create',
    name: 'FavoriteSettingCreate',
    component: () => import('@/views/FavoritesSetting.vue'),
  },
  {
    path: '/favorites/setting/:favoriteItemStatusId',
    name: 'FavoriteSettingEdit',
    component: () => import('@/views/FavoritesSetting.vue'),
    props: true,
  },
  {
    path: '/favorites/users',
    name: 'FavoriteUsers',
    component: () => import('@/views/FavoriteUsers.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account.vue'),
  },
  {
    path: '/account/email',
    name: 'AccountEmail',
    component: () => import('@/views/AccountEmail.vue'),
  },
  {
    path: '/account/password',
    name: 'AccountPassword',
    component: () => import('@/views/AccountPassword.vue'),
  },
  {
    path: '/account/phone',
    name: 'AccountPhone',
    component: () => import('@/views/AccountPhone.vue'),
  },
  {
    path: '/officialusers',
    name: 'OfficialUsers',
    component: () => import('@/views/OfficialUsers.vue'),
    props: true,
  },
  {
    path: '/talkrooms',
    name: 'TalkRooms',
    component: () => import('@/views/TalkRooms.vue'),
  },
  {
    path: '/talkroom/setting',
    name: 'TalkRoomSetting',
    component: () => import('@/views/TalkRoomSetting.vue'),
  },
  {
    path: '/talkroom/create',
    name: 'CreateTalkRoom',
    component: () => import('@/views/TalkRoom.vue'),
    props: true,
  },
  {
    path: '/talkroom/:talkRoomId',
    name: 'EditTalkRoom',
    component: () => import('@/views/TalkRoom.vue'),
    props: true,
  },
  {
    path: '/talkroom/:talkRoomId/post',
    name: 'EditTalkRoomPost',
    component: () => import('@/views/TalkRoomPost.vue'),
    props: true,
  },
  {
    path: '/membershipcards',
    name: 'MembershipCards',
    component: () => import('@/views/MembershipCards.vue'),
  },
  {
    path: '/membershipcards/create',
    name: 'CreateMembershipCard',
    component: () => import('@/views/MembershipCard.vue'),
  },
  {
    path: '/membershipcards/:membershipCardId',
    name: 'EditMembershipCard',
    component: () => import('@/views/MembershipCard.vue'),
    props: true,
  },
  {
    path: '/membershipcards/default/create',
    name: 'CreateMembershipDefaultCard',
    component: () => import('@/views/MembershipDefaultCard.vue'),
  },
  {
    path: '/membershipcards/default/:membershipCardId',
    name: 'EditMembershipDefaultCard',
    component: () => import('@/views/MembershipDefaultCard.vue'),
    props: true,
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('@/views/Messages.vue'),
  },
  {
    path: '/message/create',
    name: 'CreateMessage',
    component: () => import('@/views/Message.vue'),
  },
  {
    path: '/message/:notificationId',
    name: 'EditMessage',
    component: () => import('@/views/Message.vue'),
    props: true,
  },
  {
    path: '/histories',
    name: 'Histories',
    component: () => import('@/views/Histories.vue'),
    props: true,
  },
  {
    path: '/permissions',
    name: 'Permissions',
    component: () => import('@/views/Permissions.vue'),
    props: true,
  },
  {
    path: '/permissions/:permissionType',
    name: 'PermissionsType',
    component: () => import('@/views/Permissions.vue'),
    props: true,
  },
  {
    path: '/products',
    name: 'Products',
    component: () => import('@/views/Products.vue'),
  },
  {
    path: '/products/histories',
    name: 'ProductsHistories',
    component: () => import('@/views/ProductsHistories.vue'),
    props: true,
  },
  {
    path: '/product/create',
    name: 'CreateProduct',
    component: () => import('@/views/Product.vue'),
    props: true,
  },
  {
    path: '/product/:productId',
    name: 'EditProduct',
    component: () => import('@/views/Product.vue'),
    props: true,
  },
  {
    path: '/plans',
    name: 'Plans',
    component: () => import('@/views/Plans.vue'),
  },
  {
    path: '/plan/create',
    name: 'CreatePlan',
    component: () => import('@/views/Plan.vue'),
  },
  {
    path: '/plan/products',
    name: 'PlanProduct',
    component: () => import('@/views/PlanProducts.vue'),
  },
  {
    path: '/plan/:planId',
    name: 'EditPlan',
    component: () => import('@/views/Plan.vue'),
    props: true,
  },
  {
    path: '/gifts',
    name: 'Gifts',
    component: () => import('@/views/Gifts.vue'),
  },
  {
    path: '/gift/create',
    name: 'CreateGift',
    component: () => import('@/views/Gift.vue'),
    props: true,
  },
  {
    path: '/gift/:giftId',
    name: 'EditGift',
    component: () => import('@/views/Gift.vue'),
    props: true,
  },
  {
    path: '/gifts/conditions',
    name: 'GiftConditions',
    component: () => import('@/views/GiftConditions.vue'),
    props: true,
  },
  {
    path: '/gifts/conditions/create',
    name: 'CreateGiftCondition',
    component: () => import('@/views/GiftCondition.vue'),
  },
  {
    path: '/gifts/conditions/:giftConditionId/:giftConditionType',
    name: 'EditGiftCondition',
    component: () => import('@/views/GiftCondition.vue'),
    props: true,
  },
  {
    path: '/gifts/histories',
    name: 'GiftHistories',
    component: () => import('@/views/GiftHistories.vue'),
    props: true,
  },
  {
    path: '/mytalks',
    name: 'MyTalks',
    component: () => import('@/views/MyTalks.vue'),
  },
  {
    path: '/mytalk/:chatroomId/users',
    name: 'MyTalkUsers',
    component: () => import('@/views/MyTalkUsers.vue'),
    props: true,
  },
  {
    path: '/mytalk/create',
    name: 'MyTalkCreate',
    component: () => import('@/views/MyTalk.vue'),
  },
  {
    path: '/mytalk/:chatroomId',
    name: 'MyTalkEdit',
    component: () => import('@/views/MyTalk.vue'),
    props: true,
  },
  {
    path: '/mytalk/:chatroomId/:userId/posts',
    name: 'MyTalkPost',
    component: () => import('@/views/MyTalkPosts.vue'),
    props: true,
  },
  {
    path: '/reactions',
    name: 'Reactions',
    component: () => import('@/views/Reactions.vue'),
  },
  {
    path: '/schedule/categories',
    name: 'ScheduleCategories',
    component: () => import('@/views/ScheduleCategories.vue'),
  },
  {
    path: '/schedules',
    name: 'Schedules',
    component: () => import('@/views/Schedules.vue'),
  },
  {
    path: '/schedule/create',
    name: 'ScheduleCreate',
    component: () => import('@/views/Schedule.vue'),
  },
  {
    path: '/schedule/setting',
    name: 'ScheduleSetting',
    component: () => import('@/views/ScheduleSetting.vue'),
  },
  {
    path: '/schedule/:scheduleEventId',
    name: 'ScheduleEdit',
    component: () => import('@/views/Schedule.vue'),
    props: true,
  },
  {
    path: '/properties',
    name: 'Properties',
    component: () => import('@/views/UserProperties.vue'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/Settings.vue'),
  },
  {
    path: '/links',
    name: 'Links',
    component: () => import('@/views/Links.vue'),
  },
  {
    path: '/roles',
    name: 'Roles',
    component: () => import('@/views/Roles.vue'),
    props: true,
  },
  {
    path: '/role/create',
    name: 'CreateRole',
    component: () => import('@/views/Role.vue'),
  },
  {
    path: '/role/:roleId',
    name: 'EditRole',
    component: () => import('@/views/Role.vue'),
    props: true,
  },
  {
    path: '/announcements',
    name: 'Announcements',
    component: () => import('@/views/Announcements.vue'),
  },
  {
    path: '/announcement/:announcementId',
    name: 'Announcement',
    component: () => import('@/views/Announcement.vue'),
    props: true,
  },
  {
    path: '/campaigns',
    name: 'Campaigns',
    component: () => import('@/views/Campaigns.vue'),
    props: true,
  },
  {
    path: '/campaign/create',
    name: 'CreateCampaign',
    component: () => import('@/views/Campaign.vue'),
  },
  {
    path: '/campaign/:campaignId',
    name: 'EditCampaign',
    component: () => import('@/views/Campaign.vue'),
    props: true,
  },
  {
    path: '/birthday',
    name: 'Birthday',
    component: () => import('@/views/BirthdayMessages.vue'),
    props: true,
  },
  {
    path: '/birthday/create',
    name: 'CreateBirthday',
    component: () => import('@/views/BirthdayMessage.vue'),
  },
  {
    path: '/birthday/:notificationReservationId',
    name: 'EditBirthday',
    component: () => import('@/views/BirthdayMessage.vue'),
    props: true,
  },
  {
    path: '/videos',
    name: 'Videos',
    component: () => import('@/views/Videos.vue'),
    props: true,
  },
  {
    path: '/videos/create',
    name: 'CreateVideo',
    component: () => import('@/views/Video.vue'),
  },
  {
    path: '/videos/:videoId',
    name: 'EditVideo',
    component: () => import('@/views/Video.vue'),
    props: true,
  },
  {
    path: '/release',
    name: 'Release',
    component: () => import('@/views/Release.vue'),
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('@/views/Report.vue'),
  },
  {
    path: '/report/members',
    name: 'ReportMembers',
    component: () => import('@/views/ReportMembers.vue'),
  },
  {
    path: '/report/sales',
    name: 'ReportSales',
    component: () => import('@/views/ReportSales.vue'),
  },
  {
    path: '/report/download',
    name: 'ReportDownload',
    component: () => import('@/views/ReportDownload.vue'),
    props: true,
  },
  {
    path: '/musics/create',
    name: 'CreateMusic',
    component: () => import('@/views/Music.vue'),
  },
  {
    path: '/musics/:musicId',
    name: 'EditMusic',
    component: () => import('@/views/Music.vue'),
    props: true,
  },
  {
    path: '/musics',
    name: 'Musics',
    component: () => import('@/views/Musics.vue'),
  },
  {
    path: '/playlists',
    name: 'PlayLists',
    component: () => import('@/views/PlayLists.vue'),
  },
  {
    path: '/playlists/create',
    name: 'CreatePlayList',
    component: () => import('@/views/PlayList.vue'),
    props: true,
  },
  {
    path: '/playlists/:playlistId',
    name: 'EditPlayList',
    component: () => import('@/views/PlayList.vue'),
    props: true,
  },
  {
    path: '/digitalcontents',
    name: 'DigitalContents',
    component: () => import('@/views/DigitalContents.vue'),
  },
  {
    path: '/digitalcontents/notifications/:notificationReservationId',
    name: 'DigitalContentNotification',
    component: () => import('@/views/DigitalContentNotification.vue'),
    props: true,
  },
  {
    path: '/digitalcontents/create',
    name: 'CreateDigitalContent',
    component: () => import('@/views/DigitalContent.vue'),
  },
  {
    path: '/digitalcontents/:productId',
    name: 'UpdateDigitalContent',
    component: () => import('@/views/DigitalContent.vue'),
    props: true,
  },
  {
    path: '/purchase/preview/:type',
    name: 'NewPurchasePreview',
    component: () => import('@/views/purchase/PurchasePreview.vue'),
    props: true,
  },
  {
    path: '/purchase/preview/:type/:productId',
    name: 'PurchasePreview',
    component: () => import('@/views/purchase/PurchasePreview.vue'),
    props: true,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.public) && core.isAuthenticated) next({ name: 'Home' });
  else if (!to.matched.some((record) => record.meta.public) && !core.isAuthenticated) next({ name: 'Signin' });
  else next();
});

window.addEventListener('signout', () => {
  router.push('/signin');
});

export default router;
