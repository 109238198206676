




























import { defineComponent, computed, ref } from '@vue/composition-api';
import core from '@/admin/core';
import consoleMenu from '@/composition/consoleMenu';

export default defineComponent({
  name: 'SideBar',
  setup() {
    const isAuthenticated = ref(core.isAuthenticated);
    const isOpen = ref(core.isAuthenticated);
    window.addEventListener('signin', () => {
      isAuthenticated.value = true;
      isOpen.value = true;
    });
    window.addEventListener('signout', () => {
      isAuthenticated.value = false;
      isOpen.value = false;
    });

    const openDrawer = () => {
      isOpen.value = true;
    };

    const navItems = computed(() => consoleMenu.displayMenu);

    return { isAuthenticated, isOpen, navItems, openDrawer };
  },
});
