












import { defineComponent } from '@vue/composition-api';
import { active, message, color, timeout, icon } from '@/composition/notification';

export default defineComponent({
  name: 'Notification',
  setup() {
    return { active, message, color, icon, timeout };
  },
});
