



















import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'FcRoleLoading',
  props: {
    pageTitle: {
      type: String,
      required: false,
      default: '管理コンソール',
    },
    isLoadingMyRole: {
      type: Boolean,
      required: true,
      default: false,
    },
    myRoleSettings: {
      type: Object as PropType<{ [key: string]: boolean }>,
      required: false,
      default: undefined,
    },
  },
  setup() {
    return {};
  },
});
