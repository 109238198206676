import Vue from '@/plugins/composition-api';
import App from '@/App.vue';
import core from '@/admin/core';
import router from '@/router';
import vuetify from '@/plugins/vuetify';
import CKEditor from '@ckeditor/ckeditor5-vue2';

(async () => {
  await core.init({
    apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
    hostingUrl: process.env.VUE_APP_HOSTING_URL,
  });

  new Vue({
    router,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
  Vue.use(CKEditor);

  // ローカルストレージの削除
  const storageContents = ['notificationId:', 'productId:'];
  const deleteStorageIds: string[] = [];
  for (let i = 0, length = window.localStorage.length; i < length; ++i) {
    const key = localStorage.key(i) || '';
    // if文に使うフラグ
    const isApplicable = storageContents.some((storageContent) => key.includes(storageContent));
    if (!isApplicable) return;
    const value = localStorage.getItem(key);
    const storageJson = value ? JSON.parse(value) : null;
    const hasCreateDate = storageJson && storageJson.createDate && new Date(storageJson.createDate);

    if (storageJson && isApplicable && hasCreateDate) {
      const createDate = new Date(storageJson.createDate);
      const elapsedTime = Date.now() - createDate.getTime();
      // 作成日から30日経過しているアイテムは削除
      if (elapsedTime > 30 * 24 * 60 * 60 * 1000) deleteStorageIds.push(key);
    }
  }
  deleteStorageIds.forEach((key) => {
    if (window.localStorage.getItem(key)) window.localStorage.removeItem(key);
  });
})();
