import { computed, Ref, ref } from '@vue/composition-api';
import { getSettingConsoleMenu, ConsoleMenuProperty } from '@/admin/setting';

class ConsoleMenu {
  private _consoleMenu: Ref<ConsoleMenuProperty[]> = ref([]);
  private _displayMenu = computed(() => this._consoleMenu.value.filter((item) => item.isActive));

  get displayMenu() {
    return this._displayMenu.value;
  }
  set consoleMenu(menu: ConsoleMenuProperty[]) {
    this._consoleMenu.value = menu;
  }

  fetchConsoleMenu = async () => {
    const result = await getSettingConsoleMenu();
    this.consoleMenu = result.value;
  };
}

const consoleMenu = new ConsoleMenu();

export default consoleMenu;
